import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import lottie from 'lottie-web';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { unitConverter as uc } from '../../../styles/base';
import Image from '../../atoms/Image/Image';
import sanityImage from '../../../utils/sanityImage';
/* global localStorage */
/* global fetch */

const ProductScreenAnimation = ({
  className,
  customCss,
  placeholder,
  productScreenAnimationFile,
  screenTilt,
  secondaryImage,
  supportingImages,
}) => {
  if (!productScreenAnimationFile || !productScreenAnimationFile.asset) {
    return null;
  }

  const { asset } = productScreenAnimationFile;
  const lottieRef = React.createRef();
  const localLottie =
    typeof localStorage !== 'undefined' &&
    JSON.parse(localStorage.getItem(asset.url));
  const [lottieData, setLottieData] = useState(localLottie);
  const [loadedLottie, setLoadedLottie] = useState(false);
  const [playingLottie, setPlayingLottie] = useState(false);

  useEffect(() => {
    if (!lottieData) {
      fetch(asset.url)
        .then(response => response.json())
        .then(data => {
          const dataUpdated = Object.assign({}, data);

          dataUpdated.assets = data.assets.map((dataAsset, index) => {
            const assetUpdated = Object.assign({}, dataAsset);
            assetUpdated.p = '';
            const { resizeWidth } = dataAsset;
            const sanityWidthParam = resizeWidth ? `?w=${resizeWidth}` : '';
            assetUpdated.u = supportingImages
              .filter(supportingImage => {
                const imgRegex = RegExp(`img_${index}`);
                return imgRegex.test(supportingImage.asset.originalFilename);
              })
              .map(
                matchedImage => `${matchedImage.asset.url}${sanityWidthParam}`
              )
              .pop();
            return assetUpdated;
          });
          try {
            localStorage.setItem(asset.url, JSON.stringify(dataUpdated));
          } catch (error) {
            // TODO: could pop the oldest file out of localstorage
            console.error('shard', error); // eslint-disable-line no-console
          }

          setLottieData(dataUpdated);
        });
    } else if (!loadedLottie) {
      setLoadedLottie(true);
      const animation = lottie.loadAnimation({
        container: lottieRef.current, // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieData,
      });
      animation.addEventListener('DOMLoaded', () => {
        setPlayingLottie(true);
      });
    }
  }, [lottieData, loadedLottie, playingLottie]);

  const screenWidth = 690;
  const parentCss = css`
    max-width: ${uc(`${screenWidth}px`)};
    perspective: ${uc('800px')};
    ${customCss}
  `;

  const screenTiltDeg = screenTilt === 'left' ? '-' : '';
  const rotateDeg = screenTilt === 'left' ? '' : '-';
  const getShadowTransform = screenTiltArg => {
    switch (screenTiltArg) {
      case 'left':
        return 'right: 0;';
      case 'right':
        return 'left: 0;';
      case 'none':
        return 'left: 0;';
      default:
        return '';
    }
  };

  /* eslint-disable */
  const lottieWrapperCss = css`
    transform: ${screenTilt === 'none'
      ? ''
      : `rotate3d(0.3, 5.3, -0.4, ${screenTiltDeg}8.2deg) translate3d(0, 0, 0) rotate(${rotateDeg}1.6deg) scaleX(0.95)`};

    &::before {
      position: absolute;
      ${getShadowTransform(screenTilt)}

      bottom: 0;
      width: ${screenTilt === 'none'
        ? uc(`${screenWidth}px`)
        : uc(`${0.8 * screenWidth}px`)};
      box-shadow: ${uc('0 0 70px 70px')} rgba(21, 21, 21, 0.23);
      transform: ${getShadowTransform(screenTilt)};
      content: '';
    }

    svg {
      border: ${uc('1px')} solid #d9d9d9;
      border-radius: 2%;
    }
  `;
  /* eslint-enable */

  const lottiePlaceholderCss = css`
    position: ${playingLottie ? 'absolute' : 'relative'};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: ${uc('1px')} solid #d9d9d9;
    border-radius: 2%;
    opacity: ${playingLottie ? 0 : 1};
  `;

  const lottieRefCss = css`
    position: ${playingLottie ? 'relative' : 'absolute'};
    top: 0;
    left: 0;
  `;

  return (
    <div css={parentCss} className={className}>
      <div className="lottie-wrapper" css={lottieWrapperCss}>
        <Image
          css={lottiePlaceholderCss}
          src={sanityImage(placeholder.asset)
            .auto('format')
            .width(800)
            .fit('max')
            .url()}
          alt={placeholder.asset.alt}
        />
        <div className="lottie-ref" css={lottieRefCss} ref={lottieRef} />
        {secondaryImage && secondaryImage.asset && (
          <Image
            className="secondary-image"
            src={sanityImage(secondaryImage)
              .auto('format')
              .width(800)
              .fit('max')
              .url()}
            alt={secondaryImage.alt}
          />
        )}
      </div>
    </div>
  );
};

ProductScreenAnimation.propTypes = {
  productScreenAnimationFile: PropTypes.shape({
    asset: PropTypes.instanceOf(Object),
  }),
  screenTilt: PropTypes.string,
  placeholder: PropTypes.shape({ asset: PropTypes.instanceOf(Object) }),
  supportingImages: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  secondaryImage: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }),
  customCss: PropTypes.string,
};

ProductScreenAnimation.defaultProps = {
  productScreenAnimationFile: null,
  screenTilt: 'left',
  placeholder: null,
  supportingImages: [],
  className: '',
  secondaryImage: null,
  customCss: '',
};

export default ProductScreenAnimation;
